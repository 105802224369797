<template>
  <div class="cacheProblemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <div class="course-title">
            <div>
              <div class="name">C语言编程</div>
              <div class="detailed">
                <span>收藏数：0</span>
              </div>
            </div>
          </div>
          <!-- 介绍 -->
          <el-tabs type="border-card">
            <el-tab-pane label="基本信息">
              <div style="padding: 2rem">
                <el-row :gutter="10" type="flex" align="middle">
                  <el-col :span="3">
                    <div>题单名称</div>
                  </el-col>
                  <el-col :span="8">
                    <el-input size="medium" placeholder="请输入题单名称" v-model="input2">
                    </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="10" type="flex" align="middle" style="margin-top: 2rem">
                  <el-col :span="3">
                    <div>题单类型</div>
                  </el-col>
                  <el-col :span="8">
                    <el-input size="medium" placeholder="请输入题单类型" v-model="input2">
                    </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="10" type="flex" align="middle" style="margin-top: 2rem">
                  <el-col :span="3">
                    <div>题单描述</div>
                  </el-col>
                  <el-col :span="8">

                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>

            <!-- 题目编排 -->
            <el-tab-pane label="题目编排">
              <div style="padding: 2rem">
                <el-row :gutter="10" type="flex" align="middle">
                  <el-col :span="3">
                    <div>搜索题目</div>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="value" multiple filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" style="width: 100%" size="medium">
                      <el-option v-for="item in options" :key="item.name" :label="item.name" :value="item.name">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="2">
                    <div class="add">添 加</div>
                  </el-col>
                </el-row>
                <el-row :gutter="10" type="flex" align="middle">
                  <el-col :span="3"> </el-col>
                  <el-col :span="21">
                    <div class="describe">
                      请填写题目（如P1001,CF233A)用半英文的半角逗号隔开，支持RemoteJudge题目。
                    </div>
                  </el-col>
                </el-row>
                <!-- 题目排序 -->
                <el-row :gutter="10" type="flex" style="margin-top: 2rem">
                  <el-col :span="3">
                    <div>题目排序</div>
                  </el-col>
                  <el-col :span="21">
                    <div class="sortBox">
                      <!-- 拖拽列表 -->
                      <div class="itxst">
                        <div class="col">
                          <draggable v-model="arr1" :scroll="true" animation="100" @end="end">
                            <transition-group>
                              <div class="draggable-item" v-for="item in arr1" :key="item.id">
                                <span>{{ item.name }}</span>
                                <span class="el-icon-close"></span>
                              </div>
                            </transition-group>
                          </draggable>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="10" type="flex" align="middle">
                  <el-col :span="3"> </el-col>
                  <el-col :span="21" style="margin-top: 0.5rem">
                    <span class="topic-num">14/50</span>
                    <span class="describe">请拖动进行排序，最多支持添加50道题目，对于过多内容请创建多个题单。</span>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="save-btn">
            <div class="save">保存</div>
            <div class="cancel">删除题单</div>
          </div>
        </div>
        <div class="content-right">
          <Advertising></Advertising>
          <HotRecommend style="margin-top: 40px"></HotRecommend>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Advertising from "@/components/Advertising/Advertising.vue"
import HotRecommend from "@/views/api/com/hotRecommend";
import draggable from "vuedraggable"
export default {
  components: {
    Advertising,
    HotRecommend,
    draggable,
  },
  data () {
    return {
      //定义要被拖拽对象的数组
      arr1: [
        { id: 6, name: "item 1" },
        { id: 7, name: "item 2" },
        { id: 8, name: "item 3" },
        { id: 9, name: "item 4" },
      ],
      options: [],
      value: "",
    }
  },
  methods: {
    remoteMethod (v) {
      console.log(v)
      this.options = [
        { id: 6, name: "item 1" },
        { id: 7, name: "item 2" },
        { id: 8, name: "item 3" },
        { id: 9, name: "item 4" },
      ]
    },
    end (e) {
      console.log(this.arr1)
    },
  },
};
</script>

<style lang="less" scoped>
.cacheProblemList {
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 70%;
      .add {
        background: #00957e;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
      }
      .sortBox {
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 1rem;
        .itxst {
          height: 30rem;
          overflow: auto;
          .draggable-item {
            background-color: #f5f5f5;
            margin-bottom: 0.5rem;
            line-height: 38px;
            padding: 0 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .el-icon-close {
              cursor: pointer;
            }
          }
          .draggable-item:hover {
            background-color: #fff;
            cursor: move;
            border: 1px solid #f5f5f5;
          }
        }
      }
      .describe {
        font-size: 14px;
        line-height: 30px;
      }
      .topic-num {
        background: #00957e;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        line-height: 26px;
        display: inline-block;
        padding: 0 6px;
        margin-right: 1rem;
      }
      .course-title {
        margin-bottom: 40px;
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            margin-left: 1rem;
          }
          .el-icon-edit-outline,
          .el-icon-star-off,
          .el-icon-circle-plus-outline {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {
          font-size: 21px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
          .operation {
            margin-right: 1.5rem;
          }
        }
      }
      ::v-deep {
        .el-tabs--border-card {
          border-radius: 10px;
        }
        .el-tabs__item {
          font-size: 16px;
          height: 50px;
          width: 130px;
          line-height: 50px;
          text-align: center;
        }
        .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
          color: #00957e;
        }
        .el-tabs__item:hover {
          color: #00957e;
        }
      }
      .save-btn {
        height: 2rem;
        margin-top: 1rem;
      }
      .save,
      .cancel {
        float: right;
        bottom: 2rem;
        right: 2rem;
        width: 70px;
        cursor: pointer;
        background: #00957e;
        border-radius: 2px;
        color: #fff;
        padding: 6px 10px;
        text-align: center;
      }
      .cancel {
        background: #fff;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 2px;
        border: 1px solid #979797;
        padding: 5px 10px;
        margin-right: 0.5rem;
      }
    }
    .content-right {
      width: 27%;
    }
  }
}
</style>